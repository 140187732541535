import { Injectable } from '@angular/core';
import { AppDatabase } from '../app.database';
import { BaseRepository } from './base-repository';
import { ExamLogEntity } from '../entities/exam-log-entity';
import { ExamLogDto } from '../../model/exam/log/exam-log-dto';

@Injectable({
	providedIn: 'root'
})
export class ExamLogRepositoryService extends BaseRepository<ExamLogEntity, string, ExamLogDto> {

	constructor(protected override db: AppDatabase) {
		super(db, 'examLogs');
	}

    public convertDto(entity: ExamLogEntity): ExamLogDto {
		return {
            message: entity.message,
            type: entity.type,
            loggedDate: entity.loggedDate
		};
    }
    public convertEntity(dto: ExamLogDto): ExamLogEntity {
		return {
            message: dto.message,
            type: dto.type,
            loggedDate: dto.loggedDate
		};
    }
}
