import { IRepository } from './repository.interface';
import { AppDatabase } from '../app.database';
import Dexie from 'dexie';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

type Params = {
	[key: string]: any
}

export abstract class BaseRepository<TEntity, TKey, TDto> implements IRepository<TEntity, TKey> {

	constructor(protected db: AppDatabase, private collectionName: string) { }

	public add(value: TEntity): Observable<void> {
		return from(this.db.transaction('rw', this.db[this.collectionName], async () => {
			await (this.db[this.collectionName] as Dexie.Table<TEntity, TKey>).put(value);
		}));
	}

	public bulkAdd(values: TEntity[], key: string) {
		return from(this.db.transaction('rw', this.db[this.collectionName], async () => {
			await (this.db[this.collectionName] as Dexie.Table<TEntity, TKey>).bulkAdd(values, [key]);
		}));
	}

	public update(value: TEntity, key: TKey): Observable<void> {
		return from(this.db.transaction('rw', this.db[this.collectionName], async () => {
			await (this.db[this.collectionName] as Dexie.Table<TEntity, TKey>).update(key, {value});
		}));
	}

	public put(value: TEntity, key?: TKey): Observable<void> {
		return from(this.db.transaction('rw', this.db[this.collectionName], async () => {
			await (this.db[this.collectionName] as Dexie.Table<TEntity, TKey>).put(value, key);
		}));
	}

	public bulkPut(values: TEntity[], key: string) {
		return from(this.db.transaction('rw', this.db[this.collectionName], async () => {
			await (this.db[this.collectionName] as Dexie.Table<TEntity, TKey>).bulkAdd(values, [key]);
		}));
	}

	public clear(): Observable<void> {
		return from((this.db[this.collectionName] as Dexie.Table<TEntity, TKey>).clear());
	}

	public count(): Observable<number> {
		return from((this.db[this.collectionName] as Dexie.Table<TEntity, TKey>).count());
	}

	public isEmpty(): Observable<boolean> {
		return from(this.count()).pipe(map(count => count > 0));
	}

	

	public get(key: string, value: any): Observable<TEntity> {
		const param: Params = {};
		param[key] = value;
		return from((this.db[this.collectionName] as Dexie.Table<TEntity, TKey>).where(param).first()) as Observable<TEntity>;
	}

	public getMultiple(key: string, value: any): Observable<TEntity[]> {
		return from((this.db[this.collectionName] as Dexie.Table<TEntity, TKey>).where(key).equals(value).toArray());
	}

	public getAll(): Observable<TEntity[]> {
		return from((this.db[this.collectionName] as Dexie.Table<TEntity, TKey>).toArray());
	}

	public getWhere(key: string, values: any[]): Observable<TEntity[]> {
		return from((this.db[this.collectionName] as Dexie.Table<TEntity, TKey>).where(key).anyOf(values).toArray())
	}

	public abstract convertDto(entity: TEntity): TDto;
	public abstract convertEntity(dto: TDto): TEntity;
}
