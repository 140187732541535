import { ExamLogType } from "./exam-log-type.enum";

export class ExamLogDto {
	public message: string;
	public type: ExamLogType;
	public loggedDate: Date;

	constructor(message: string, type: ExamLogType) {
		this.message = message;
		this.type = type;
		this.loggedDate = new Date();
	}
}
