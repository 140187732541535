import { IAnswer } from "../../model/answer/answer.interface";
import { QuestionLayoutDTO } from "../../model/exam/question-layout-dto";

export class QuestionAnswerEntity {
    public id?: number;
    public questionAnswerId!: string;
    public candidateQuestionId!: string;
    public answerTypeId!: string;
    public potentialScore!: number;
    public answer!: IAnswer;
    public layout!: QuestionLayoutDTO;
	public layoutTypeIdentifier!: number;
	public labelText!: string;
	public labelPositionIdentifier?: number;
	public labelAlignmentIdentifier?: number;
}
