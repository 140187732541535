import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { QuestionSectionStateDto } from '../../model/exam/state/question-section-state-dto';
import { CandidateQuestionEntity } from '../entities/candidate-question-entity';
import { QuestionSectionEntity } from '../entities/question-section-entity';
import { CandidateQuestionRepositoryService } from '../repositories/candidate-question-repository.service';
import { QuestionSectionRepositoryService } from '../repositories/question-section-repository.service';
import { QuestionSectionDto } from '../../model/exam/question-section-dto';
import { KeyValueRepositoryService } from '../repositories/key-value-repository.service';

@Injectable({
	providedIn: 'root'
})
export class QuestionSectionDataService {
	constructor(private candidateQuestionRepository: CandidateQuestionRepositoryService,
		private questionSectionRepository: QuestionSectionRepositoryService,
		private keyValueDataRepository: KeyValueRepositoryService) {
		this.getSectionQuestions = this.getSectionQuestions.bind(this);
	}

	public get(key: string): Observable<QuestionSectionDto> {
		return this.questionSectionRepository.get('questionSectionId', key)
			.pipe(map((data: QuestionSectionEntity) => {
				return this.questionSectionRepository.convertDto(data);
			}));
	}

	public getAll(): Observable<QuestionSectionDto[]> {
		return this.questionSectionRepository.getAll()
			.pipe(map(sectionEntities => sectionEntities.map(sectionEntity => this.questionSectionRepository.convertDto(sectionEntity))));
	}

	public put(section: QuestionSectionDto): Observable<void> {
		const entity: QuestionSectionEntity = this.questionSectionRepository.convertEntity(section);
		return this.questionSectionRepository.put(entity);
	}

	public getBySectionNumber(sectionNumber: number) {
		return this.questionSectionRepository.getBySectionNumber(sectionNumber)
			.pipe(map((data: QuestionSectionEntity) => {
				return this.questionSectionRepository.convertDto(data);
			}));
	}

	public getCurrentSection(): Observable<QuestionSectionDto> {
		return this.keyValueDataRepository.getCurrentSectionNumber()
			.pipe(mergeMap((currentSectionNumber: number) => this.getBySectionNumber(currentSectionNumber)))
	}

	public getNextSection(sectionNumber: number): Observable<QuestionSectionDto> {
		return this.questionSectionRepository.getBySectionNumber(++sectionNumber)
			.pipe(map((data: QuestionSectionEntity) => {

					return this.questionSectionRepository.convertDto(data);
			}));
	}

	public getSectionQuestions(): Observable<QuestionSectionStateDto[]> {
		return this.questionSectionRepository.getAll()
			.pipe(switchMap((entities: QuestionSectionEntity[]) => forkJoin(entities.map((sectionEntity: QuestionSectionEntity) => {
				return forkJoin({
					section: of(sectionEntity),
					questionStates: this.candidateQuestionRepository.getSectionQuestions(sectionEntity.questionSectionId)
				}).pipe(map((data: { section: QuestionSectionEntity, questionStates: CandidateQuestionEntity[] }) => {
					const sectionState: QuestionSectionStateDto = {
						...this.questionSectionRepository.convertDto(data.section),
						questionStates: data.questionStates.map(candidateQuestionEntity => {
							return {
								question: this.candidateQuestionRepository.convertDto(candidateQuestionEntity),
								layoutItems: []
							};
						})
					};
					return sectionState;
				}));
			}))));
	}

	public updateTimeAdjustment(section: QuestionSectionDto): Observable<void> {
		return this.get(section.id)
			.pipe(map((localSection: QuestionSectionDto) => {
				localSection.timeAdjustment = (localSection.timeAdjustment ? localSection.timeAdjustment : 0) + section.timeAdjustment!;
				return localSection;
			}))
			.pipe(mergeMap((localSection: QuestionSectionDto) => this.put(localSection)));
	}
}
