import { Injectable } from '@angular/core';
import { AppDatabase } from '../app.database';
import { QuestionSectionEntity } from '../entities/question-section-entity';
import { BaseRepository } from './base-repository';
import { QuestionSectionDto } from '../../model/exam/question-section-dto';
import { Observable, from } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class QuestionSectionRepositoryService extends BaseRepository<QuestionSectionEntity, string, QuestionSectionDto> {

	constructor(protected override db: AppDatabase) {
		super(db, 'questionSections');
	}

	public getBySectionNumber(number: number): Observable<QuestionSectionEntity> {
		return from(this.db.questionSections.where('orderIndex').equals(number).first()) as Observable<QuestionSectionEntity>;
	}

    public convertDto(entity: QuestionSectionEntity): QuestionSectionDto {
		return {
			id: entity.questionSectionId,
			breakDuration: entity.breakDuration,
			duration: entity.duration,
			maximumScore: entity.maximumScore,
			minimumScore: entity.minimumScore,
			name: entity.name,
			orderIndex: entity.orderIndex,
			breakStarted: entity.breakStarted,
			breakEnded: entity.breakEnded,
			sectionStarted: entity.sectionStarted,
			sectionEnded: entity.sectionEnded,
			timeAdjustment: entity.timeAdjustment,
			paused: entity.paused,
			examCandidateId: entity.examCandidateId,
			canNavigateAway: entity.canNavigateAway
		};
    }
    public convertEntity(dto: QuestionSectionDto): QuestionSectionEntity {
		return {
			questionSectionId: dto.id,
			breakDuration: dto.breakDuration,
			duration: dto.duration,
			maximumScore: dto.maximumScore,
			minimumScore: dto.minimumScore,
			name: dto.name,
			orderIndex: dto.orderIndex,
			breakStarted: dto.breakStarted,
			breakEnded: dto.breakEnded,
			sectionStarted: dto.sectionStarted,
			sectionEnded: dto.sectionEnded,
			timeAdjustment: dto.timeAdjustment,
			paused: dto.paused,
			examCandidateId: dto.examCandidateId,
			canNavigateAway: dto.canNavigateAway
		};
    }
}
