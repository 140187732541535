import { IAnswer } from "../answer.interface";
import { ReorderAnswerRowDto } from "./reorder-answer-row-dto";

export class ReorderAnswerDto implements IAnswer {
	public id!: string;
	public answerId!: string;
	public answerTypeIdentifier!: number;
	public answerType!: string;
	public answerTypeId!: string;
	public reorderAnswerRows!: ReorderAnswerRowDto[];
	public potentialScore!: number;

	public answered(): boolean {
		if (this.reorderAnswerRows && this.reorderAnswerRows.length) {
			return this.reorderAnswerRows.some(x => x.orderIndex !== x.initialOrderIndex);
		}
		return false;
	}
}
