import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExamLogDto } from '../../model/exam/log/exam-log-dto';
import { ExamLogEntity } from '../entities/exam-log-entity';
import { ExamLogRepositoryService } from '../repositories/exam-log-repository.service';

@Injectable({
    providedIn: 'root'
})
export class ExamLogDataService {
    constructor (private examLogRepository: ExamLogRepositoryService) { }

    public getAll(): Observable<ExamLogDto[]> {
        return this.examLogRepository.getAll()
            .pipe(map((examLogEntities: ExamLogEntity[]) => examLogEntities.map((examLogEntity: ExamLogEntity) => this.examLogRepository.convertDto(examLogEntity))));
    }
}
