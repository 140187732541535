import { Injectable } from '@angular/core';
import { interval, Subject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AutoSaveService {
    private autoSaveAnswerSource: Subject<void> = new Subject<void>();
	public autoSaveAnswerChange$ = this.autoSaveAnswerSource.asObservable();

    public isAutoSaving: boolean = false;

    constructor() { }

    public beginAutoSaving(): void {
        if (!this.isAutoSaving) {
            this.isAutoSaving = true;
            this.startAutoSaveProcess();
        }
    }

    public stopAutoSaving(): void {
        this.isAutoSaving = false;
    }

    public triggerAutoSave(): void {
        this.autoSaveAnswerSource.next();
    }

    public startAutoSaveProcess(): void {
        interval(30000)
            .pipe(takeWhile(() => this.isAutoSaving))
            .subscribe(() => {
                this.triggerAutoSave();
            });
    }
}
