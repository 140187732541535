import { IAnswer } from "../answer.interface";


export class DocumentUploadAnswerDto implements IAnswer {
	public id!: string;
    public answerId!: string;
    public answerType!: string;
    public answerTypeId!: string;
    public answerTypeIdentifier!: number;
	public docmentUrl!: string;
	public answerJSON!: string;
	public potentialScore!: number;
	public hasInteracted: boolean = false;

	public answered(): boolean {
		return this.hasInteracted;
	}
}
