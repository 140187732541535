import { IAnswer } from "../answer.interface";
import { TableAnswerCellDto } from "./table-answer-cell-dto";

export class TableAnswerDto implements IAnswer {
	public id!: string;
	public answerId!: string;
	public answerTypeIdentifier!: number;
	public answerType!: string;
	public answerTypeId!: string;
	public cols!: number;
	public rows!: number;
	public tableAnswerCells: TableAnswerCellDto[] = [];
	public potentialScore!: number;

	public answered(): boolean {
		if (this.tableAnswerCells && this.tableAnswerCells.length) {

			if (this.tableAnswerCells.some(x => x.isEditable && x.text)) {
				return true;
			}
		}
		return false;
	}
}
