import { IAnswer } from "../answer.interface";
import { OpenResponseAnswerDto } from "./open-response-answer-dto";


export class OpenResponseAnswerGroupDto implements IAnswer {
	public answerId!: string;
	public answerTypeIdentifier!: number;
	public answerType!: string;
	public answerTypeId!: string;
	public answers!: OpenResponseAnswerDto[];
	public potentialScore!: number;

	public answered(): boolean {
		if (this.answers && this.answers.length) {

			if (this.answers.some(x => x.answerText)) {
				return true;
			}
		}
		return false;
	}
}
