import { IAnswer } from "../answer.interface";


export class ScaleAnswerDto implements IAnswer {
    public answerId!: string;
    public answerType!: string;
    public answerTypeId!: string;
    public answerTypeIdentifier!: number;
	public pointCount!: number;
	public leftText!: string;
	public middleText!: string;
	public rightText!: string;
	public answerJSON!: string;
	public potentialScore!: number;

	public answered(): boolean {
		if (this.answerJSON) {
			return true;
		}
		return false;
	}
}
