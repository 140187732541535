import { Injectable } from '@angular/core';
import { QuestionAnswerStateDTO } from '../../model/exam/state/question-answer-state-dto';
import { AppDatabase } from '../app.database';
import { QuestionAnswerEntity } from '../entities/question-answer-entity';
import { BaseRepository } from './base-repository';
import { AnswerFactoryService } from '../services/answer-factory.service';

@Injectable({
	providedIn: 'root'
})
export class QuestionAnswerRepositoryService extends BaseRepository<QuestionAnswerEntity, string, QuestionAnswerStateDTO> {

	constructor(protected override db: AppDatabase) {
		super(db, 'questionAnswers');
	}

	public convertDto(entity: QuestionAnswerEntity): QuestionAnswerStateDTO {
		return {
			id: entity.questionAnswerId,
			candidateQuestionId: entity.candidateQuestionId,
			answerTypeId: entity.answerTypeId,
			potentialScore: entity.potentialScore,
			answer: AnswerFactoryService.make(entity.answer),
			layout: entity.layout,
			layoutTypeIdentifier: entity.layoutTypeIdentifier,
			labelText: entity.labelText,
			labelAlignmentIdentifier: entity.labelAlignmentIdentifier,
			labelPositionIdentifier: entity.labelPositionIdentifier
		}
	}

	public convertEntity(dto: QuestionAnswerStateDTO): QuestionAnswerEntity {
		return {
			questionAnswerId: dto.id,
			candidateQuestionId: dto.candidateQuestionId,
			answerTypeId: dto.answerTypeId,
			potentialScore: dto.potentialScore,
			answer: dto.answer,
			layout: dto.layout,
			layoutTypeIdentifier: dto.layoutTypeIdentifier,
			labelText: dto.labelText,
			labelAlignmentIdentifier: dto.labelAlignmentIdentifier,
			labelPositionIdentifier: dto.labelPositionIdentifier
		}
	}
}
