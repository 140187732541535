import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CandidateQuestionDto } from '../../model/exam/candidate-question-dto';
import { AppDatabase } from '../app.database';
import { CandidateQuestionEntity } from '../entities/candidate-question-entity';
import { BaseRepository } from './base-repository';

@Injectable({
	providedIn: 'root'
})
export class CandidateQuestionRepositoryService extends BaseRepository<CandidateQuestionEntity, string, CandidateQuestionDto> {

	constructor(protected override db: AppDatabase) {
		super(db, 'candidateQuestions');
	}

	public getLastQuestion(): Observable<CandidateQuestionEntity> {
		return from(this.db.candidateQuestions.orderBy("orderIndex").reverse().first()) as Observable<CandidateQuestionEntity>;
	}

	public getSectionQuestions(sectionId: string): Observable<CandidateQuestionEntity[]> {
		return from(this.db.candidateQuestions.where("sectionId").equals(sectionId).toArray());
	}

	public checkFlaggedQuestions(): Observable<boolean> {
		return from(this.db.candidateQuestions.where("flagged").equals("true").count())
			.pipe(map((val: number) => val > 0));
	}

	public checkNonAttemptedResponses(): Observable<boolean> {
		return from(this.db.candidateQuestions.where("responseCount").equals(0).count())
			.pipe(map((val: number) => val > 0));
	}

	public convertDto(entity: CandidateQuestionEntity): CandidateQuestionDto {
		return {
			id: entity.candidateQuestionId,
			examCandidateId: entity.examCandidateId,
			questionTypeId: entity.questionTypeId,
			questionText: entity.questionText,
			potentialScore: entity.potentialScore,
			orderIndex: entity.orderIndex,
			responseCount: entity.responseCount,
			flagged: entity.flagged,
			sectionId: entity.sectionId,
			first: entity.first,
			last: entity.last
		};
	}
	public convertEntity(dto: CandidateQuestionDto): CandidateQuestionEntity {
		return {
			candidateQuestionId: dto.id,
			examCandidateId: dto.examCandidateId,
			questionTypeId: dto.questionTypeId,
			questionText: dto.questionText,
			potentialScore: dto.potentialScore,
			orderIndex: dto.orderIndex,
			responseCount: dto.responseCount,
			flagged: dto.flagged,
			sectionId: dto.sectionId,
			first: dto.first,
			last: dto.last
		};
	}
}
