import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { APIResponse } from '../../model/request/APIResponse';
import { ConnectionService } from '../connection.service';
import { ErrorService } from '../error/error.service';
import { ErrorCode } from '../error/application-error-handler';

@Injectable({
	providedIn: 'root'
})
export class RequestService {

	constructor(private http: HttpClient,
		@Inject('servicesBaseUrl') private servicesBaseUrl: string,
		private errorService: ErrorService,
		private connectionService: ConnectionService) {
		this.processResponse = this.processResponse.bind(this);
	}

	public get<T>(url: string): Observable<APIResponse<T>> {
		return this.http.get<APIResponse<T>>(`${this.servicesBaseUrl}/api/${url}`)
			.pipe(catchError(err => this.processError<T>(err)))
			.pipe(map(this.processResponse));
	}

	public post<T>(url: string, body: any): Observable<APIResponse<T>> {
		return this.http.post<APIResponse<T>>(`${this.servicesBaseUrl}/api/${url}`, body)
			.pipe(catchError(err => this.processError<T>(err)))
			.pipe(map(this.processResponse));
	}

	public put<T>(url: string, body: any): Observable<APIResponse<T>> {
		return this.http.put<APIResponse<T>>(`${this.servicesBaseUrl}/api/${url}`, body)
			.pipe(catchError(err => this.processError<T>(err)))
			.pipe(map(this.processResponse));
	}

	public delete<T>(url: string): Observable<APIResponse<T>> {
		return this.http.delete<APIResponse<T>>(`${this.servicesBaseUrl}/api/${url}`)
			.pipe(catchError(err => this.processError<T>(err)))
			.pipe(map(this.processResponse));
	}

	private processError<T>(error: HttpErrorResponse): Observable<APIResponse<T>> {
		const isTimeout: boolean = error.name.toLocaleLowerCase().indexOf('timeout') !== -1;

		return of({
			responseCode: isTimeout ? ErrorCode.ServerTimeout : ErrorCode.ServerUnavailable,
			responseMessage: '',
			responseData: null as unknown as T,
			responseError: ''
		});
	}

	private processResponse<T>(response: APIResponse<T>): APIResponse<T> {

		if (response.responseCode === ErrorCode.ServerTimeout || response.responseCode === ErrorCode.ServerUnavailable) {
			if (this.connectionService.isOnline()) {
				this.connectionService.goOffline();
				this.errorService.showConnectionErrorModal();
			}
		} else if (response.responseCode !== 200) {
			this.errorService.goToErrorPage(response.responseCode, response.responseData as unknown as string);

			if (response.responseData) {
				throw response.responseData as unknown as string;
			}
		}

		return response;
	}
}
