import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { interval, Observable, of } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { catchError, mergeMap, takeWhile } from 'rxjs/operators';
import { APIResponse } from '../model/request/APIResponse';
import { DashboardService } from './dashboard.service';

export enum ConnectionState {
    Online,
    Offline
}

@Injectable({
    providedIn: 'root'
})
export class ConnectionService {
    private connectionState: ConnectionState = ConnectionState.Online;

    private connectionStateSource: Subject<ConnectionState> = new Subject<ConnectionState>();
	public connectionStateChange$ = this.connectionStateSource.asObservable();

    constructor(private http: HttpClient,
                @Inject('servicesBaseUrl') private servicesBaseUrl: string,
                private dashboardService: DashboardService) { }

    public goOnline(): void {
        this.connectionState = ConnectionState.Online;
        this.connectionStateSource.next(this.connectionState);
        this.dashboardService.sendOnlineNotification();
    }

    public isOnline(): boolean {
        return this.connectionState === ConnectionState.Online;
    }

    public goOffline(): void {
        this.connectionState = ConnectionState.Offline;
        this.connectionStateSource.next(this.connectionState);
        this.beginOfflinePolling();
    }

    public isOffline(): boolean {
        return this.connectionState === ConnectionState.Offline;
    }

    public canContactServer(): Observable<APIResponse<void>> {
        return this.connectionTest();
    }

    private beginOfflinePolling(): void {
        interval(10000)
            .pipe(takeWhile(() => this.isOffline()))
            .pipe(mergeMap(() => this.connectionTest()))
            .subscribe((response) => {
                if (response) {
                    this.goOnline();
                }
        });
    }

    public connectionTest(): Observable<APIResponse<void>> {
        return this.http.get<APIResponse<void>>(`${this.servicesBaseUrl}/api/connection`).pipe(catchError(() => of()));
    }
}
