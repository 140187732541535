import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { APIResponse } from '../../model/request/APIResponse';
import { ErrorModalComponent } from '../../site/error/error-modal/error-modal.component';
import { AuthorisationService } from '../authorisation/authorisation.service';
import { AutoSaveService } from '../autosave.service';
import { ConnectionService } from '../connection.service';
import { ErrorCode } from '../error/application-error-handler';
import { ErrorService } from '../error/error.service';
import { LockoutService } from '../lockout.service';

@Injectable({
  providedIn: 'root'
})
export class CandidateRequestService {

  constructor(private http: HttpClient,
              @Inject('servicesBaseUrl') private servicesBaseUrl: string,
              private authorisationService: AuthorisationService,
              private errorService: ErrorService,
              private connectionService: ConnectionService,
              private autoSaveService: AutoSaveService,
              private lockoutService: LockoutService) {
      this.processResponse = this.processResponse.bind(this);
      this.processError = this.processError.bind(this);
   }

  public get<T>(url: string): Observable<APIResponse<T>> {
    const authHeader: HttpHeaders = this.authorisationService.getAuthHeader();
    return this.http.get<APIResponse<T>>(`${this.servicesBaseUrl}/api/${url}`, { headers: authHeader })
                            .pipe(catchError(err => this.processError<T>(err)))
                            .pipe(map(this.processResponse));
  }

  public post<T>(url: string, body: any): Observable<APIResponse<T>> {
    const authHeader: HttpHeaders = this.authorisationService.getAuthHeader();
    return this.http.post<APIResponse<T>>(`${this.servicesBaseUrl}/api/${url}`, body, { headers: authHeader })
                            .pipe(catchError(err => this.processError<T>(err)))
                            .pipe(map(this.processResponse));
  }

  public put<T>(url: string, body: any): Observable<APIResponse<T>> {
    const authHeader: HttpHeaders = this.authorisationService.getAuthHeader();
    return this.http.put<APIResponse<T>>(`${this.servicesBaseUrl}/api/${url}`, body, { headers: authHeader })
                            .pipe(catchError(err => this.processError<T>(err)))
                            .pipe(map(this.processResponse));
  }

  public delete<T>(url: string): Observable<APIResponse<T>> {
    const authHeader: HttpHeaders = this.authorisationService.getAuthHeader();
    return this.http.delete<APIResponse<T>>(`${this.servicesBaseUrl}/api/${url}`, { headers: authHeader })
                            .pipe(catchError(err => this.processError<T>(err)))
                            .pipe(map(this.processResponse));
  }

  private processError<T>(error: HttpErrorResponse): Observable<APIResponse<T>> {
    const isTimeout: boolean = error.name.toLocaleLowerCase().indexOf('timeout') !== -1;

    return of({
      responseCode: isTimeout ? ErrorCode.ServerTimeout : ErrorCode.ServerUnavailable,
      responseMessage: '',
      responseData: null as unknown as T,
      responseError: ''
    });
  }

  private processResponse<T>(response: APIResponse<T>): APIResponse<T> {
    if (response.responseCode === ErrorCode.ServerTimeout || response.responseCode === ErrorCode.ServerUnavailable) {
      if (this.connectionService.isOnline()) {
        this.connectionService.goOffline();
        this.errorService.showConnectionErrorModal();
      }
    } else if (response.responseCode !== 200) {
        this.autoSaveService.stopAutoSaving();
        this.lockoutService.stopBlurWatching();
        
        let errorDialogReference: MatDialogRef<ErrorModalComponent, any> = this.errorService.showErrorModal(response.responseCode, response.responseData as unknown as string)!;
        
        if (errorDialogReference) {
          errorDialogReference.afterClosed().subscribe(() => {
            this.autoSaveService.startAutoSaveProcess();
            this.lockoutService.startBlurWatching();
          });
        }

        if (response.responseData) {
          throw response.responseData as unknown as string;
        }
    }

    return response;
  }
}
