import { Inject, Injectable } from '@angular/core';
import Dexie from 'dexie';
import { DATABASE_CONFIG_TOKEN } from './config/database-config-token';
import { IDbContext } from './database-context.interface';
import { AssetEntity } from './entities/asset-entity';
import { CandidateQuestionEntity } from './entities/candidate-question-entity';
import { ExamLogEntity } from './entities/exam-log-entity';
import { KeyValueEntity } from './entities/key-value-entity';
import { QuestionAnswerEntity } from './entities/question-answer-entity';
import { QuestionSectionEntity } from './entities/question-section-entity';
import { TimedBlockEntity } from './entities/timed-block-entity';

@Injectable({
	providedIn: 'root'
})
export class AppDatabase extends Dexie {
	[key:string]:any;
	
	public keyValues!: Dexie.Table<KeyValueEntity, string>;
	public candidateQuestions!: Dexie.Table<CandidateQuestionEntity, string>;
	public questionAnswers!: Dexie.Table<QuestionAnswerEntity, string>;
	public assets!: Dexie.Table<AssetEntity, string>;
	public questionSections!: Dexie.Table<QuestionSectionEntity, string>;
	public examLogs!: Dexie.Table<ExamLogEntity, string>;
	public timedBlocks!: Dexie.Table<TimedBlockEntity, string>;

	constructor(@Inject(DATABASE_CONFIG_TOKEN) private context: IDbContext) {
		super(context.databaseName);
		this.version(context.databaseVersion || 1).stores(context.schema);

		this.keyValues.mapToClass(KeyValueEntity);
		this.candidateQuestions.mapToClass(CandidateQuestionEntity);
		this.questionAnswers.mapToClass(QuestionAnswerEntity);
		this.assets.mapToClass(AssetEntity);
		this.questionSections.mapToClass(QuestionSectionEntity);
		this.examLogs.mapToClass(ExamLogEntity);
		this.timedBlocks.mapToClass(TimedBlockEntity);
	}
}
