export class QuestionSectionEntity {
	public id?: number;
	public questionSectionId!: string;
	public name!: string;
	public minimumScore!: number;
	public maximumScore!: number;
	public breakDuration?: number;
	public duration?: number;
	public orderIndex!: number;
	public breakStarted!: Date;
	public breakEnded!: Date;
	public sectionStarted?: Date;
	public sectionEnded?: Date;
	public timeAdjustment?: number;
	public paused?: number;
	public examCandidateId!: string;
    public canNavigateAway!: boolean;
}
