import { IAnswer } from "../answer.interface";
import { FormAnswerItemDTO } from "./form-answer-item-dto";

export class FormAnswerDTO implements IAnswer {
    public answerId!: string;
    public answerType!: string;
    public answerTypeId!: string;
    public answerTypeIdentifier!: number;
    public potentialScore!: number;
	public formItems!: FormAnswerItemDTO[];

	public answered(): boolean {
		return this.formItems!.filter(x => x.userInput != null && x.userInput.toString().trim() != "").length > 0;
	}
}
