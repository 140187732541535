import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { APIResponse } from "../model/request/APIResponse";
import { AuthorisationService } from "./authorisation/authorisation.service";

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    constructor(private authorisationService: AuthorisationService,
                private http: HttpClient,
                @Inject('servicesBaseUrl') private servicesBaseUrl: string) { }

    public sendOnlineNotification(): Observable<APIResponse<void>> {
        const authHeader: HttpHeaders = this.authorisationService.getAuthHeader();
        return this.http.post<APIResponse<void>>(`${this.servicesBaseUrl}/api/notification/online`, null, { headers: authHeader });
    }

    public sendLockedNotification(): Observable<APIResponse<void>> {
        const authHeader: HttpHeaders = this.authorisationService.getAuthHeader();
        return this.http.post<APIResponse<void>>(`${this.servicesBaseUrl}/api/notification/locked`, null, { headers: authHeader });
    }

	public sendUnlockedNotification(reason: string): Observable<APIResponse<void>> {
		const authHeader: HttpHeaders = this.authorisationService.getAuthHeader();
		return this.http.post<APIResponse<void>>(`${this.servicesBaseUrl}/api/notification/unlocked/${reason}`, null, { headers: authHeader });
    }

    public sendQuestionsAnsweredNotification(questionsAnswered: number): Observable<APIResponse<void>> {
        const authHeader: HttpHeaders = this.authorisationService.getAuthHeader();
        return this.http.post<APIResponse<void>>(`${this.servicesBaseUrl}/api/notification/answered/${questionsAnswered}`, null, { headers: authHeader });
    }
}
