import { AnswerTypeIdentifier } from "../../model/answer/answer-type-enum";
import { IAnswer } from "../../model/answer/answer.interface";
import { CalculatorAnswerDto } from "../../model/answer/calculator/calculator-answer-dto";
import { ChartAnswerDTO } from "../../model/answer/chart/chart-answer-dto";
import { OpenResponseAnswerGroupDto } from "../../model/answer/open-response/open-response-group-answer-dto";
import { ReorderAnswerDto } from "../../model/answer/reorder/reorder-answer-dto";
import { ScaleAnswerDto } from "../../model/answer/scale/scale-answer-dto";
import { TableAnswerDto } from "../../model/answer/table/table-answer-dto";
import { _3DShapeAnswerDto } from "../../model/answer/3d-shape/3d-shape-answer-dto";
import { MultiChoiceGroupAnswerDTO } from "../../model/answer/multi-choice/multi-choice-group-answer-dto";
import { DocumentUploadAnswerDto } from "../../model/answer/document-upload/document-upload-answer-dto";
import { FormAnswerDTO } from "../../model/answer/form/form-answer-dto";

export class AnswerFactoryService {

	public static make(answer: IAnswer): IAnswer {
		switch (answer.answerTypeIdentifier) {

			case AnswerTypeIdentifier.Calculator:
				return Object.assign(new CalculatorAnswerDto(), answer);
			case AnswerTypeIdentifier.Chart:
				return Object.assign(new ChartAnswerDTO(), answer);
			case AnswerTypeIdentifier.OpenResponse:
				return Object.assign(new OpenResponseAnswerGroupDto(), answer);
			case AnswerTypeIdentifier.Reorder:
				return Object.assign(new ReorderAnswerDto(), answer);
			case AnswerTypeIdentifier.Scale:
				return Object.assign(new ScaleAnswerDto(), answer);
			case AnswerTypeIdentifier.Table:
				return Object.assign(new TableAnswerDto(), answer);
			case AnswerTypeIdentifier._3DShape:
				return Object.assign(new _3DShapeAnswerDto(), answer);
			case AnswerTypeIdentifier.MultiChoiceSingleAnswer:
			case AnswerTypeIdentifier.MultiChoiceMultiAnswer:
				return Object.assign(new MultiChoiceGroupAnswerDTO(), answer);
			case AnswerTypeIdentifier.Form:
				return Object.assign(new FormAnswerDTO(), answer);
            case AnswerTypeIdentifier.DocumentUpload:
				return Object.assign(new DocumentUploadAnswerDto(), answer);
			default:
				return answer;
		}
	}

}
