import { IAnswer } from "../answer.interface";

export class CalculatorAnswerDto implements IAnswer {
    public answerId!: string;
    public answerType!: string;
    public answerTypeId!: string;
	public answerTypeIdentifier!: number;
	public answerJson!: string;
	public showLogByDefault!: boolean;
	public potentialScore!: number;

	public answered(): boolean {
		if (this.answerJson) {
			return true;
		}
		return false;
	}
}
