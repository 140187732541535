import { IAnswer } from "../answer.interface";
import { MultiChoiceAnswerDTO } from "./multi-choice-answer-dto";

export class MultiChoiceGroupAnswerDTO implements IAnswer {
    public answerId!: string;
    public answerType!: string;
    public answerTypeId!: string;
    public answerTypeIdentifier!: number;
    public potentialScore!: number;
    public answers!: MultiChoiceAnswerDTO[];

    public answered(): boolean {
		if (this.answers && this.answers.length) {

			if (this.answers.some(x => x.selected)) {
				return true;
			}
		}
		return false;
	}
}