export class CandidateQuestionEntity {
    public id?: number;
    public candidateQuestionId!: string;
    public examCandidateId!: string;
    public questionTypeId!: string;
    public questionText!: string;
    public potentialScore: number = 0;
    public orderIndex!: number;
    public responseCount!: number;
    public flagged!: boolean;
	public sectionId!: string;
	public first?: boolean;
	public last?: boolean;
}
