import { AssetTypeIdentifier } from "../../model/exam/asset-type-identifier";
import { QuestionLayoutDTO } from "../../model/exam/question-layout-dto";

export class AssetEntity {
    public id?: string;
    public assetId!: string;
    public candidateQuestionId!: string;
    public questionAssetTypeId!: string;
    public name!: string;
    public url!: string;
    public layout!: QuestionLayoutDTO;
    public layoutTypeIdentifier!: number;
	public assetTypeIdentifier!: AssetTypeIdentifier;
	public labelText!: string;
	public labelPositionIdentifier?: number;
	public labelAlignmentIdentifier?: number;
}
