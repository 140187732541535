import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { iif, interval, Observable, of } from 'rxjs';
import { first, map, mergeMap, switchMap, takeWhile } from 'rxjs/operators';
import { KeyValueDataService } from '../database/services/key-value-data.service';
import { ExamLogDto } from '../model/exam/log/exam-log-dto';
import { ExamLogType } from '../model/exam/log/exam-log-type.enum';
import { AutoSaveService } from './autosave.service';
import { DashboardService } from './dashboard.service';

export enum LockedState {
    Unlocked,
    Locked
}

export enum BlurType {
    Body,
    IFrame
}

@Injectable({
    providedIn: 'root'
})
export class LockoutService {
    private blurEvents: BlurType[] = [];
    private blurWatchingActive: boolean = false;

    constructor(private keyValueDataService: KeyValueDataService,
                private router: Router,
                private dashboardService: DashboardService,
                private autoSaveService: AutoSaveService) {}

    public isLocked(): Observable<boolean> {
        return this.keyValueDataService.getLockedState()
            .pipe(map((lockedState: LockedState) => lockedState === LockedState.Locked));
    }

    public lockCandidateOut(): void {
         this.keyValueDataService.getExamCodeChecksum()
             .pipe(switchMap((checksum: string) => iif(
                 () => !!checksum,
                 this.keyValueDataService.getLockedState()
                     .pipe(map((lockedState: LockedState) => {
                         if (lockedState === LockedState.Unlocked) {
                             const log = new ExamLogDto('Candidate has been locked', ExamLogType.CandidateLocked);
                             let logs = [];

                             if (localStorage['examLogs']) {
                             if (JSON.parse(localStorage['examLogs']).length) {
                                 logs = JSON.parse(localStorage['examLogs']);
                                 logs.push(log);
                             } else {
                                 logs = [log];
                             }
                             } else {
                             logs = [log];
                             }

                             localStorage['examLogs'] = JSON.stringify(logs);
                         }
                     }))
                     .pipe(mergeMap(() => this.keyValueDataService.setLockedState(LockedState.Locked)))
                     .pipe(mergeMap(() => this.dashboardService.sendLockedNotification()))
                     .pipe(map(() => this.redirectLocked())),
                     of(null)
             )))
             .pipe(first())
             .subscribe(() => {
                 this.blurEvents.length = 0;
                 this.stopBlurWatching();
             });
    }

    public redirectLocked(): void {
        this.router.navigateByUrl('exam/locked');
    }

    public startBlurWatching(): void {
        this.keyValueDataService.getExamCodeChecksum().subscribe((checksum: string) => {
            if (!!checksum && !this.blurWatchingActive) {
                this.blurWatchingActive = true;
                this.startBlurWatchingProcess();
            }
        });
    }

    public stopBlurWatching(): void {
        this.blurWatchingActive = false;
    }

    public consumeBlurItems(): boolean {
        if (this.blurEvents.length) {
            const lockCandidateOut: boolean = this.blurEvents.indexOf(BlurType.IFrame) === -1;

            this.blurEvents.length = 0;

            if (lockCandidateOut) {
                this.stopBlurWatching();
            }

            return lockCandidateOut;
        }

        return false;
    }

    public addBlurItem(blurItem: BlurType): void {
        if (this.blurWatchingActive) {
            this.blurEvents.push(blurItem);
        }
    }

    private startBlurWatchingProcess(): void {
        interval(250)
            .pipe(takeWhile(() => this.blurWatchingActive))
            .pipe(map(() => this.consumeBlurItems()))
            .subscribe((lockCandidate: boolean) => {
                if (lockCandidate) {
                    this.autoSaveService.stopAutoSaving();
                    this.lockCandidateOut();
                }
        });
    }
}
