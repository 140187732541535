import { IAnswer } from "../answer.interface";
import { _3DShapeAssetDto } from "./3d-shape-asset-dto";
import { _3DShapeAnswerDefaultLinesDto } from "./3d-shape-default-lines-dto";

export class _3DShapeAnswerDto implements IAnswer {

	public id!: string;
	public answerId!: string;
	public answerType!: string;
	public answerTypeId!: string;
	public answerTypeIdentifier!: number;
	public additionalText!: string;
	public height?: number;
	public width?: number;
	public answerJSON!: string;
	public defaultLines: _3DShapeAnswerDefaultLinesDto[] = [];
	public assetInfo!: _3DShapeAssetDto;
	public potentialScore!: number;

	public answered(): boolean {
		if (this.answerJSON) {
			return true;
		}
		return false;
	}
}
