import { Injectable } from '@angular/core';
import { forkJoin, from, Observable, of } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { CandidateQuestionEntity } from '../database/entities/candidate-question-entity';
import { QuestionAnswerEntity } from '../database/entities/question-answer-entity';
import { CandidateQuestionRepositoryService } from '../database/repositories/candidate-question-repository.service';
import { KeyValueRepositoryService } from '../database/repositories/key-value-repository.service';
import { QuestionAnswerRepositoryService } from '../database/repositories/question-answer-repository.service';
import { ExamLogDataService } from '../database/services/exam-log-data.service';
import { QuestionSectionDataService } from '../database/services/question-section-data.service';
import { CandidateQuestionDto } from '../model/exam/candidate-question-dto';
import { ExamLogDto } from '../model/exam/log/exam-log-dto';
import { QuestionSectionDto } from '../model/exam/question-section-dto';
import { QuestionAnswerStateDTO } from '../model/exam/state/question-answer-state-dto';
import { QuestionStateDto } from '../model/exam/state/question-state-dto';
import { UploadDataDto } from '../model/exam/upload-data';
import { AuthorisationService } from './authorisation/authorisation.service';
import { ExamService } from './exam.service';

class UploadPayload {
	uploadData!: UploadDataDto;
	checksum!: string;
	examLogs!: ExamLogDto[];
}

@Injectable({
    providedIn: 'root'
})
export class SynchroniseService {
    constructor(private examService: ExamService,
                private candidateQuestionRepository: CandidateQuestionRepositoryService,
                private questionAnswerRepository: QuestionAnswerRepositoryService,
                private keyValueRepository: KeyValueRepositoryService,
                private questionSectionService: QuestionSectionDataService,
				private examLogDataService: ExamLogDataService,
				private authorisationService: AuthorisationService) { }

    public uploadExamViaPin(pin: string): Observable<void> {
		return this.GetUploadData()
			.pipe(switchMap((uploadData: UploadPayload) => this.examService.synchonizeExamViaPin(uploadData.uploadData, uploadData.checksum, uploadData.examLogs, pin)))
			.pipe(map(() => this.clearLogsFromLocalStorage()));
	}

	public uploadExamViaAccessToken(): Observable<void> {
		const accessToken = this.authorisationService.getAccessToken();
		return this.GetUploadData()
			.pipe(switchMap((uploadData: UploadPayload) => this.examService.synchonizeExamViaAccessToken(uploadData.uploadData, uploadData.checksum, uploadData.examLogs, accessToken)))
			.pipe(map(() => this.clearLogsFromLocalStorage()));
	}

	public calculateChecksum(): Observable<void> {
        const candidateQuestionsObservable = this.candidateQuestionRepository.getAll();
        const questionAnswersObservable = this.questionAnswerRepository.getAll();
        const questionSectionsObservable = this.questionSectionService.getAll();

        return forkJoin({ candidateQuestionEntities: candidateQuestionsObservable, questionAnswerEntities: questionAnswersObservable, sections: questionSectionsObservable })
            .pipe(mergeMap((data: { candidateQuestionEntities: CandidateQuestionEntity[], questionAnswerEntities: QuestionAnswerEntity[], sections: QuestionSectionDto[] }) => {
                const candidateQuestions: CandidateQuestionDto[] = data.candidateQuestionEntities.map(this.candidateQuestionRepository.convertDto);
                const questionAnswers: QuestionAnswerStateDTO[] = data.questionAnswerEntities.map(this.questionAnswerRepository.convertDto);
                const questionStates: QuestionStateDto[] = [];
                const sections: QuestionSectionDto[] = data.sections;

                for (const candidateQuestion of candidateQuestions) {
                    questionStates.push({
                        question: candidateQuestion,
                        layoutItems: questionAnswers.filter(x => x.candidateQuestionId === candidateQuestion.id)
                    });
                }

                const uploadData: UploadDataDto = {
                    questionStates,
                    sections
                };

                return from(crypto.subtle.digest('SHA-256', new TextEncoder().encode(JSON.stringify(uploadData))))
                        .pipe(map((checksumArrayBuffer: ArrayBuffer) => new Uint8Array(checksumArrayBuffer)))
                        .pipe(mergeMap((checksumByteArray: Uint8Array) => this.keyValueRepository.setChecksum(checksumByteArray)));
            }));
    }

	private GetUploadData(): Observable<UploadPayload> {
		const candidateQuestionsObservable = this.candidateQuestionRepository.getAll();
		const questionAnswersObservable = this.questionAnswerRepository.getAll();
		const questionSectionsObservable = this.questionSectionService.getAll();
		const checksumObservable = this.keyValueRepository.getChecksum();
		const examLogsObservable = this.examLogDataService.getAll().pipe(map((existingLogs) => this.getLogsFromLocalStorage(existingLogs)));

		return forkJoin({
			candidateQuestionEntities: candidateQuestionsObservable,
			questionAnswerEntities: questionAnswersObservable,
			checksum: checksumObservable,
			sections: questionSectionsObservable,
			examLogs: examLogsObservable
		})
		.pipe(switchMap((data: {
			candidateQuestionEntities: CandidateQuestionEntity[],
			questionAnswerEntities: QuestionAnswerEntity[],
			checksum: Uint8Array,
			sections: QuestionSectionDto[],
			examLogs: ExamLogDto[]
		}) => {
			const candidateQuestions: CandidateQuestionDto[] = data.candidateQuestionEntities.map(this.candidateQuestionRepository.convertDto);
			const questionAnswers: QuestionAnswerStateDTO[] = data.questionAnswerEntities.map(this.questionAnswerRepository.convertDto);
			const checksum: Uint8Array = data.checksum;
			const strChecksum: string = btoa(String.fromCharCode(...checksum));

			const questionStates: QuestionStateDto[] = [];

			for (const candidateQuestion of candidateQuestions) {
				questionStates.push({
					question: candidateQuestion,
					layoutItems: questionAnswers.filter(x => x.candidateQuestionId === candidateQuestion.id)
				});
			}

			const uploadData: UploadDataDto = {
				questionStates,
				sections: data.sections
			};

			return of({
				uploadData,
				checksum: strChecksum,
				examLogs: data.examLogs
			});
		}));
	}

    private getLogsFromLocalStorage(existingLogs: ExamLogDto[]): ExamLogDto[] {

		if (localStorage['examLogs']) {
			const logs = JSON.parse(localStorage['examLogs']) as ExamLogDto[];
			return existingLogs.concat(logs);
		}

		return existingLogs;
	}

	private clearLogsFromLocalStorage(): void {

		if (localStorage['examLogs']) {
			localStorage.removeItem('examLogs');
		}
	}
}
