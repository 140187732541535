import { IAnswer } from "../answer.interface";


export class ChartAnswerDTO implements IAnswer {
	public chartTypeId!: string;
	public answerJSON!: string;
	public chartTypeIdentifier!: number;
	public answerId!: string;
	public answerType!: string;
	public answerTypeId!: string;
	public answerTypeIdentifier!: number;
	public potentialScore!: number;

	public answered(): boolean {
		if (this.answerJSON) {
			return true;
		}
		return false;
	}
}
