export enum AnswerTypeIdentifier {
	MultiChoiceSingleAnswer = 1,
	MultiChoiceMultiAnswer,
	OpenResponse,
	Chart,
	Table,
	Reorder,
	Calculator,
	_3DShape,
	Scale,
	Form,
	DocumentUpload
}
